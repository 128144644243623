<template>
  <div id="revenue-user">
    <div class="heading text-start">
      <div class="left-heading"><h2>Total messages not replied</h2></div>
    </div>
    <div class="content">
      <ValidationObserver>
        <div class="table-header row">
          <ValidationProvider
            name="Search project, division"
            v-slot="{ errors }"
            class="input-group search"
            rules="max:255"
          >
            <span class="input-group-text mb-2">
              <SearchIcon />
            </span>
            <input
              type="text"
              class="form-control mb-2"
              placeholder="Search project, division"
              v-model="filter.id"
              @input="debouncedQuery"
            />
            <span class="error text-left f-w3">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="input-date-group date row align-items-center">
            <div class="col">
              <CalenderIcon />
            </div>
            <div class="col">
              <vue-monthly-picker
                v-model="filter.date"
                :monthLabels="MONTH_LABEL"
                placeHolder="YYYY/MM"
                dateFormat="YYYY/MM"
                alignment="left"
                :clearOption="false"
                inputClass="month-picker"
                :max="maxMonth"
                @selected="changeDate"
              >
              </vue-monthly-picker>
            </div>
          </div>
          <div></div>
        </div>
        <div class="button-group justify-content-end d-flex">
          <button
            type="button"
            class="btn btn-warning text-white"
            v-on:click.prevent.stop="handleSubmit()"
          >
            Resolve
          </button>
        </div>
      </ValidationObserver>
      <div class="table-content">
        <table class="table color-8B9DA5 text-start">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  class="checkbox"
                  :checked="allSelected"
                  @click="selectAll"
                />
              </th>
              <th scope="col" class="align-middle">Project Name</th>
              <th scope="col" class="align-middle text-center">Date</th>
              <th scope="col" class="align-middle">Details messages</th>
              <th scope="col" class="align-middle">Message link</th>
              <th scope="col" class="align-middle text-center">Remind times</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(message, index) in messageList" :key="index">
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="message.selected"
                  @click="selectItem(message, !message.selected)"
                />
              </td>
              <td class="pt-3 pb-3">{{ message.projectName }}</td>
              <td class="pt-3 pb-3 text-center">{{ message.time }}</td>
              <td class="pt-3 pb-3" style="width: 50%">
                <div style="display: flex">
                  <span
                    style="
                      display: block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      flex: 1;
                      width: 10px;
                    "
                  >
                    {{ message.content }}</span
                  >
                </div>
              </td>
              <td class="pt-3 pb-3">
                <a :href="message.link">{{ message.link }}</a>
              </td>
              <td class="pt-3 pb-3 text-center">{{ message.remindTimes }}</td>
              <td>
                <button v-on:click="showModal(message)" class="btn">
                  <EyeIcon />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <BasePaginate
      :current-page.sync="paginate.currentPage"
      :total-page.sync="paginate.total"
      :per-page.sync="paginate.perPage"
      :total-record.sync="paginate.totalRecord"
      @onPageChanged="changePage"
      v-if="paginate.total > 0"
    />
    <div class="modal-edit-announcement">
      <div
        class="modal fade"
        :class="{ show: isShowModal }"
        id="edit-announcement-modal"
        tabindex="-1"
        aria-labelledby="edit-announcement-modal"
        aria-hidden="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <span style="font-weight: bold">Message Detail</span>
              <button
                type="btn"
                class="close"
                aria-label="Close"
                v-on:click="closeModal()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <span>{{ messageDetail }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePaginate from "@/components/BasePaginate";
import SearchIcon from "@/components/Icon/SearchIcon";
import EyeIcon from "@/components/Icon/EyeIcon";
import * as _ from "lodash";
import { PER_PAGE_NUMBER } from "@/utils/const";
import { MONTH_LABEL } from "@/utils/const";
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";
import CalenderIcon from "@/components/Icon/CalenderIcon";
import { MESSAGES, MODAL } from "@/utils/const";

export default {
  name: "NoReply",
  components: {
    SearchIcon,
    EyeIcon,
    BasePaginate,
    VueMonthlyPicker,
    CalenderIcon,
  },
  data() {
    return {
      selected: [],
      messageList: [],
      isShowModal: false,
      messageDetail: "",
      paginate: {
        currentPage: 1,
        total: 0,
        totalRecord: 0,
      },
      pointer: 0,
      filter: {
        id: null,
        date: moment(),
      },
      MONTH_LABEL,
      maxMonth: moment(),
    };
  },
  created() {
    (this.filter.date = this.$route.query.date || moment()),
      this.getMentionBotList();
  },

  computed: {
    allSelected() {
      return this.messageList.every((itm) => itm.selected);
    },
  },

  watch: {
    messageList: {
      handler() {
        this.messageList.map((itm) => {
          const checkExist = this.selected.includes(itm.id);
          if (checkExist) {
            itm.selected = true;
          } else {
            itm.selected = false;
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    handleSubmit() {
      if (this.selected.length === 0) {
        return;
      }
      if (!confirm(`Are you sure to resolve`)) {
        return;
      }
      const body = {
        listBotLogIds: this.selected,
      };
      this.$store
        .dispatch("bot/resolve", body)
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$toast(
            MESSAGES.RESOLVE_SUCCESS,
            MODAL.MODAL_SUCCESS,
            MODAL.MODAL_TYPE_SUCCESS
          );
          this.getMentionBotList();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
      this.$root.$refs.loading.start();
    },
    selectAll() {
      let all_s = this.allSelected;
      this.messageList.map((itm) => {
        itm.selected = !all_s;
        if (itm.selected && !this.selected.includes(itm.id)) {
          this.selected.push(itm.id);
        }
        if (!itm.selected) {
          const selectClone = this.selected.filter((id) => id !== itm.id);
          this.selected = selectClone;
        }
      });
    },

    selectItem(item, selectedItem) {
      if (selectedItem && !this.selected.includes(item.id)) {
        this.selected.push(item.id);
        item.selected = true;
      }
      if (!selectedItem && this.selected.includes(item.id)) {
        item.selected = false;
        const selectClone = this.selected.filter((id) => id !== item.id);
        this.selected = selectClone;
      }
    },
    async getMentionBotList() {
      this.$root.$refs.loading.start();
      const params = { order: "DESC" };
      if (this.filter.id !== null && this.filter.id !== "") {
        params.q = this.filter.id;
      } else {
        params.q = undefined;
      }
      if (this.filter.date !== null && this.filter.date !== "") {
        params.time = this.$dayjs(this.filter.date).format("YYYY-MM-DD");
      } else {
        params.time = undefined;
      }
      if (this.filter.user_type !== 1) {
        params.user_type = this.filter.user_type;
      } else {
        params.user_type = undefined;
      }
      params.limit = PER_PAGE_NUMBER;
      if (this.paginate.currentPage) {
        params.page = this.paginate.currentPage;
      }

      if (this.$route.query.division && this.$route.query.division !== "" && this.$route.query.project === null) {
        params.division = this.$route.query.division;
      }

      if (this.$route.query.project && this.$route.query.project !== "") {
        params.project = this.$route.query.project;
      }
      params.isNotReply = true;
      this.$store
        .dispatch("bot/botsLog", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.paginate.currentPage = response.meta.page;
          this.paginate.totalRecord = response.meta.itemCount;
          this.paginate.total = response.meta.pageCount;
          this.messageList = response.data.map((message) => {
            message.time = moment(message.createdAt).format("YYYY-MM-DD");
            message.link = `${message.chatworkRoom}-${message.chatworkId}`;
            message.selected = false;
            return message;
          });
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    changeDate() {
      if (this.filter.date === null) {
        this.filter.date = moment();
      }
      this.getMentionBotList();
    },
    showModal(message) {
      this.isShowModal = true;
      this.messageDetail = message.content;
    },
    closeModal() {
      this.isShowModal = false;
    },
    handleItemClick(item) {
      console.log(item);
    },
    changePage(page) {
      this.paginate.currentPage = page;
      this.getMentionBotList();
    },
    debouncedQuery: _.debounce(function () {
      this.paginate.currentPage = 1;
      this.getMentionBotList();
    }, 500),
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/mention.scss";
</style>
<style lang='scss'>
.vue-monthly-picker .picker .monthItem .item {
    border-top: none !important;
}</style>